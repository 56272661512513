/** @module Docs:Router */

// Plugins
import VueRouter from 'vue-router';

// Components
import NotFound from 'docs/components/NotFound.vue';
import OrganizationAPI from 'docs/components/OrganizationAPI.vue';
import EventAPI from 'docs/components/EventAPI.vue';
import PackageAPI from 'docs/components/PackageAPI.vue';
import DirectDataAPI from 'docs/components/DirectDataAPI.vue';
import DirectDataFilesAPI from 'docs/components/DirectDataFilesAPI.vue';
import Limitations from 'docs/pages/Limitations.vue';
import GettingStarted from 'docs/pages/GettingStarted.vue';
import Home from 'docs/pages/Home.vue';

// Constants
import {
    ROUTE_GETTING_STARTED_API_DOC,
    ROUTE_LIMITATIONS_API_DOC,
    ROUTE_ORG_API_DOC,
    ROUTE_EVENT_API_DOC,
    ROUTE_PACKAGE_API_DOC,
    ROUTE_DIRECT_DATA_API_DOC,
    ROUTE_DIRECT_DATA_FILES_API_DOC
} from 'libs/utils/constants';

/**
 * Route name of the organization API page.
 * @const {String} ORG_API_ROUTE
 */
export const LIMITATIONS_ROUTE = 'apiLimitations';

/**
 * Route name of getting started API page
 * @const {String} ORG_API_ROUTE
 */
export const GETTING_STARTED_ROUTE = 'gettingStarted';

/**
 * Route name of the organization API page.
 * @const {String} ORG_API_ROUTE
 */
export const ORG_API_ROUTE = 'organizationApi';

/**
 * Route name of the event API page.
 * @const {String} EVENT_API_ROUTE
 * @private
 */
export const EVENT_API_ROUTE = 'eventApi';

/**
 * Route name of the package API page.
 * @const {String} PACKAGE_API_ROUTE
 */
export const PACKAGE_API_ROUTE = 'packageApi';

/**
 * Route name of the Direct Data API page.
 * @const {String} DIRECT_DATA_API_ROUTE
 */
export const DIRECT_DATA_API_ROUTE = 'directDataApi';

/**
 * Route name of the Direct Data Files API page.
 * @const {String} DIRECT_DATA_FILES_API_ROUTE
 */
export const DIRECT_DATA_FILES_API_ROUTE = 'directDataFilesApi';

/**
 * Creates the router for the main vue instance.
 *
 * @param {import('vue').VueConstructor} Vue the vue instance
 *
 * @returns {import('vue-router').default} the vue router instance
 *
 * @private
 */
export function createRouter(Vue) {
    // Install the router
    Vue.use(VueRouter);

    // Creates the router instance
    return new VueRouter({
        base: '/docs',
        mode: 'history',
        routes: [
            // Not found catch all
            { path: '*', component: NotFound },

            { path: '/', component: Home, alias: '/docs' },

            // API Getting Started
            { path: ROUTE_GETTING_STARTED_API_DOC, component: GettingStarted, name: GETTING_STARTED_ROUTE },

            // API Limitations
            { path: ROUTE_LIMITATIONS_API_DOC, component: Limitations, name: LIMITATIONS_ROUTE },

            // API Direct Data
            { path: ROUTE_DIRECT_DATA_API_DOC, component: DirectDataAPI, name: DIRECT_DATA_API_ROUTE },

            // API Direct Data Files
            { path: ROUTE_DIRECT_DATA_FILES_API_DOC, component: DirectDataFilesAPI, name: DIRECT_DATA_FILES_API_ROUTE, meta: { useCustomSecurity: true } },

            // Organization endpoints
            { path: ROUTE_ORG_API_DOC, component: OrganizationAPI, name: ORG_API_ROUTE, alias: '/org' },

            // Events endpoints
            { path: ROUTE_EVENT_API_DOC, component: EventAPI, name: EVENT_API_ROUTE, alias: '/workspace' },

            // Packages endpoints
            { path: ROUTE_PACKAGE_API_DOC, component: PackageAPI, name: PACKAGE_API_ROUTE, alias: '/package' }
        ]
    });
}
